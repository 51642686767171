import { Authenticator } from './Authenticator';
export class OktaPassword extends Authenticator {
    canVerify(values) {
        return !!(values.credentials || values.password);
    }
    mapCredentials(values) {
        const { credentials, password } = values;
        if (!credentials && !password) {
            return;
        }
        return credentials || { passcode: password };
    }
    getInputs(idxRemediationValue) {
        var _a;
        return Object.assign(Object.assign({}, (_a = idxRemediationValue.form) === null || _a === void 0 ? void 0 : _a.value[0]), { name: 'password', type: 'string', required: idxRemediationValue.required });
    }
}
