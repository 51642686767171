import { getOAuthUrls, getDefaultTokenParams } from './util';
import { clone } from '../util';
import { postToTokenEndpoint } from './endpoints/token';
import { handleOAuthResponse } from './handleOAuthResponse';
// codeVerifier is required. May pass either an authorizationCode or interactionCode
export function exchangeCodeForTokens(sdk, tokenParams, urls) {
    urls = urls || getOAuthUrls(sdk, tokenParams);
    // build params using defaults + options
    tokenParams = Object.assign({}, getDefaultTokenParams(sdk), clone(tokenParams));
    const { authorizationCode, interactionCode, codeVerifier, clientId, redirectUri, scopes, ignoreSignature, state } = tokenParams;
    var getTokenOptions = {
        clientId,
        redirectUri,
        authorizationCode,
        interactionCode,
        codeVerifier,
    };
    return postToTokenEndpoint(sdk, getTokenOptions, urls)
        .then((response) => {
        // `handleOAuthResponse` hanadles responses from both `/authorize` and `/token` endpoints
        // Here we modify the response from `/token` so that it more closely matches a response from `/authorize`
        // `responseType` is used to validate that the expected tokens were returned
        const responseType = ['token']; // an accessToken will always be returned
        if (scopes.indexOf('openid') !== -1) {
            responseType.push('id_token'); // an idToken will be returned if "openid" is in the scopes
        }
        const handleResponseOptions = {
            clientId,
            redirectUri,
            scopes,
            responseType,
            ignoreSignature,
        };
        return handleOAuthResponse(sdk, handleResponseOptions, response, urls)
            .then((response) => {
            // For compatibility, "code" is returned in the TokenResponse. OKTA-326091
            response.code = authorizationCode;
            response.state = state;
            return response;
        });
    })
        .finally(() => {
        sdk.transactionManager.clear();
    });
}
