import * as remediators from './remediators';
import { IdxFeature } from './types';
export function isTerminalResponse(idxResponse) {
    const { neededToProceed, interactionCode } = idxResponse;
    return !neededToProceed.length && !interactionCode;
}
export function canSkipFn(idxResponse) {
    return idxResponse.neededToProceed.some(({ name }) => name === 'skip');
}
export function canResendFn(idxResponse) {
    return Object.keys(idxResponse.actions).some(actionName => actionName.includes('resend'));
}
export function getMessagesFromIdxRemediationValue(value) {
    if (!value || !Array.isArray(value)) {
        return;
    }
    return value.reduce((messages, value) => {
        if (value.messages) {
            messages = [...messages, ...value.messages.value];
        }
        if (value.form) {
            const messagesFromForm = getMessagesFromIdxRemediationValue(value.form.value) || [];
            messages = [...messages, ...messagesFromForm];
        }
        if (value.options) {
            let optionValues = [];
            value.options.forEach(option => {
                if (!option.value || typeof option.value === 'string') {
                    return;
                }
                optionValues = [...optionValues, option.value];
            });
            const messagesFromOptions = getMessagesFromIdxRemediationValue(optionValues) || [];
            messages = [...messages, ...messagesFromOptions];
        }
        return messages;
    }, []);
}
export function getMessagesFromResponse(idxResponse) {
    var _a;
    let messages = [];
    const { rawIdxState, neededToProceed } = idxResponse;
    // Handle global messages
    const globalMessages = (_a = rawIdxState.messages) === null || _a === void 0 ? void 0 : _a.value.map(message => message);
    if (globalMessages) {
        messages = [...messages, ...globalMessages];
    }
    // Handle field messages for current flow
    for (let remediation of neededToProceed) {
        const fieldMessages = getMessagesFromIdxRemediationValue(remediation.value);
        if (fieldMessages) {
            messages = [...messages, ...fieldMessages];
        }
    }
    // API may return identical error on same field, filter by i18n key
    const seen = {};
    messages = messages.reduce((filtered, message) => {
        var _a;
        const key = (_a = message.i18n) === null || _a === void 0 ? void 0 : _a.key;
        if (key && seen[key]) {
            return filtered;
        }
        seen[key] = message;
        filtered = [...filtered, message];
        return filtered;
    }, []);
    return messages;
}
export function getEnabledFeatures(idxResponse) {
    const res = [];
    const { actions, neededToProceed } = idxResponse;
    if (actions['currentAuthenticator-recover']) {
        res.push(IdxFeature.PASSWORD_RECOVERY);
    }
    if (neededToProceed.some(({ name }) => name === 'select-enroll-profile')) {
        res.push(IdxFeature.REGISTRATION);
    }
    if (neededToProceed.some(({ name }) => name === 'redirect-idp')) {
        res.push(IdxFeature.SOCIAL_IDP);
    }
    if (neededToProceed.some(({ name }) => name === 'unlock-account')) {
        res.push(IdxFeature.ACCOUNT_UNLOCK);
    }
    return res;
}
export function getAvailableSteps(idxResponse) {
    const res = [];
    const remediatorMap = Object.values(remediators).reduce((map, remediatorClass) => {
        // Only add concrete subclasses to the map
        if (remediatorClass.remediationName) {
            map[remediatorClass.remediationName] = remediatorClass;
        }
        return map;
    }, {});
    for (let remediation of idxResponse.neededToProceed) {
        const T = remediatorMap[remediation.name];
        if (T) {
            const remediator = new T(remediation);
            res.push(remediator.getNextStep(idxResponse.context));
        }
    }
    return res;
}
export function filterValuesForRemediation(idxResponse, values) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const valuesForRemediation = idxResponse.neededToProceed[0].value.reduce((res, entry) => {
        const { name } = entry;
        res[name] = values[name];
        return res;
    }, {});
    return valuesForRemediation;
}
