import { getOAuthUrls } from './oauth';
export function createOAuthMeta(sdk, tokenParams) {
    const issuer = sdk.options.issuer;
    const urls = getOAuthUrls(sdk, tokenParams);
    const oauthMeta = {
        issuer,
        urls,
        clientId: tokenParams.clientId,
        redirectUri: tokenParams.redirectUri,
        responseType: tokenParams.responseType,
        responseMode: tokenParams.responseMode,
        scopes: tokenParams.scopes,
        state: tokenParams.state,
        nonce: tokenParams.nonce,
        ignoreSignature: tokenParams.ignoreSignature,
    };
    if (tokenParams.pkce === false) {
        // Implicit flow or authorization_code without PKCE
        return oauthMeta;
    }
    const pkceMeta = Object.assign(Object.assign({}, oauthMeta), { codeVerifier: tokenParams.codeVerifier, codeChallengeMethod: tokenParams.codeChallengeMethod, codeChallenge: tokenParams.codeChallenge });
    return pkceMeta;
}
