import { VerificationCodeAuthenticator } from './VerificationCodeAuthenticator';
export class OktaVerifyTotp extends VerificationCodeAuthenticator {
    mapCredentials(values) {
        const { verificationCode } = values;
        if (!verificationCode) {
            return;
        }
        return { totp: verificationCode };
    }
}
